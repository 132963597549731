import styled from 'styled-components';

const HintText = styled.div`
  display: flex;
  text-align: center;
  padding: 10px 18px;
  color: #000;
  font-size: 1.4rem;
`;

export default HintText;
