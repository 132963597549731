import styled from 'styled-components';

const CTAButton = styled.button.attrs(() => ({className: 'cta-button'}))`
  display: flex;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 15px;
  padding: 10px 20px 10px 20px;
  font-weight: 900;
  border: 0px;
  min-width: 200px;

  @media (max-width: 805px) {
    padding: 10px 20px;
    width: 100%;
  }

  text-decoration: none;
  background-color: #ff3660;
  color: #fff;

  &:hover {
    ${({disabled}) => !disabled && `opacity: 0.7;`}
  }

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }

  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

export default CTAButton;
