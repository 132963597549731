/* eslint-disable relay/graphql-syntax */
// eslint-disable-next-line no-unused-vars
import React, {useCallback, useEffect, useState} from 'react';
import {graphql, navigate} from 'gatsby';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import PickIndustry from '../../components/PickIndustry';
import useMergeState from '../../hooks/useMergeState';
import styled from 'styled-components';
import SignUpForm from '../../components/SignUpForm';
import SuccessSignUp from '../../components/SuccessSignUp';
import Loader from '../../components/Loader';
import {useLocation} from '@reach/router';
import API from '../../helpers/api';
import NavBarSimple from '../../components/NavBarSimple';
import {BREAKPOINT} from '../../helpers';
import {Helmet} from 'react-helmet';
import {Step, StepLabel, Stepper} from '@mui/material';
import {endsWith} from 'lodash';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 52px 24px;
  align-items: center;
  min-height: calc(100vh - 100px);

  @media (max-width: ${BREAKPOINT}px) {
    padding: 52px 18px 30px 16px;
  }

  @media (max-width: 805px) {
    padding: 52px 18px 20px 16px;
  }
`;

const StepperContainer = styled.div`
  margin: 16px 0px;
  width: 100%;

  @media (max-width: ${BREAKPOINT}px) {
    margin: 4px 0px;
  }
`;

const STEP_PATHS = {
  1: '?step=1',
  2: '?step=2',
  3: '?step=3',
};

const STEPS = ['Pick industry', 'Second step', 'Download app'];

function SignUpPage() {
  const [step, setStep] = useState(1);
  // const [size, setSize] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setformData] = useMergeState({});
  const location = useLocation();
  const {t} = useTranslation();
  const {search} = location;

  useEffect(() => {
    if (typeof window !== 'undefined' && window.fbq) {
      if (endsWith(location.pathname, '/sign-up/') || endsWith(location.pathname, '/sign-up/?step=1')) {
        console.log('viewContent');
        window.fbq('track', 'ViewContent');
      }
    }
  }, [location]);

  useEffect(() => {
    const _step = Object.keys(STEP_PATHS).find((key) => STEP_PATHS[key] === search);
    if (_step) {
      setStep(Number(_step));
    } else {
      setStep(1);
    }
  }, [search]);

  const handleIndustryClick = useCallback((industry) => {
    window?.analytics?.identify({industry: industry.name});
    localStorage.setItem('industry', JSON.stringify(industry));

    window.fbq('track', 'Lead', {industry: industry?.name});
    window?.klaviyo.push(['track', 'Picked industry', {industry: industry?.name}]);

    navigate(STEP_PATHS[2]);
  }, []);

  // const handleSizeClick = useCallback((size) => {
  //   setSize(size);

  //   const refId = = window.analytics.user().anonymousId();

  //   const input = {
  //     refId,
  //     size,
  //   };

  //   const body = JSON.stringify(input);

  //   try {
  //     API.post('leads/partner', {method: 'POST', body});
  //   } catch (e) {
  //     console.log(e);
  //   }

  //   navigate(STEP_PATHS[3]);
  // }, []);

  const handleFormSubmit = useCallback(async () => {
    setIsLoading(true);

    const industry = JSON.parse(localStorage.getItem('industry'));

    const input = {
      refId: localStorage.getItem('refId'),
      categoryId: industry.refId,
      ...formData,
    };

    const body = JSON.stringify(input);

    try {
      await API.post('providers/sign-up', {method: 'POST', body});

      const {name, email, organizationName, phoneNumber, country} = formData;
      const identityProperties = {
        $email: email,
        $first_name: name,
        $organization: organizationName,
        $country: country,
      };

      window?.klaviyo.identify(identityProperties, () => {
        window.klaviyo.push(['track', 'Provider signed up', {industry: industry.name, phoneNumber, email}]);
      });

      if (typeof window !== 'undefined' && window.fbq) {
        window.fbq('track', 'CompleteRegistration', input);
      }

      navigate(STEP_PATHS[3]);
    } catch (e) {
      alert(e);
    }

    setIsLoading(false);
  }, [formData]);

  const handleFormDataChange = useCallback(
    (event) => {
      let {id: key, value} = event.target;

      setformData({[key]: value});
    },
    [setformData],
  );

  const handlePhoneNumberChange = useCallback(
    (value) => {
      setformData({phoneNumber: value});
    },
    [setformData],
  );

  return (
    <main>
      <Helmet>
        <title>CindyApp - Sign up</title>
      </Helmet>
      <NavBarSimple />
      <Container>
        {isLoading && <Loader />}
        {step < 3 && (
          <StepperContainer>
            <Stepper
              sx={{width: '100%', paddingTop: '10px', paddingBottom: '10px'}}
              alternativeLabel
              activeStep={step - 1}
            >
              {STEPS.map((label, index) => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      sx: {width: '3rem', height: '3rem', fill: step > index ? '#8070fd' : 'rgba(0, 0, 0, 0.38)'},
                    }}
                  />
                </Step>
              ))}
            </Stepper>
          </StepperContainer>
        )}
        {step === 1 && <PickIndustry onClick={handleIndustryClick} />}
        {step === 2 && (
          <SignUpForm
            buttonTitle="Next"
            data={formData}
            onChange={handleFormDataChange}
            onPhoneNumberChange={handlePhoneNumberChange}
            onSubmit={handleFormSubmit}
          />
        )}
        {step === 3 && (
          <SuccessSignUp
            phoneNumber={formData.phoneNumber}
            onGoBackClick={() => navigate('/')}
            goBackButtonTitle={t('GO BACK')}
          />
        )}
      </Container>
    </main>
  );
}

export default SignUpPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
