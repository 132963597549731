// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Trans} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import kebabCase from 'lodash/kebabCase';
import PromptText from './PromptText';
import BeautyImage from '../assets/images/industry-graphics/beauty.png';
import SportImage from '../assets/images/industry-graphics/sport.png';
import SpaImage from '../assets/images/industry-graphics/spa.png';
import AutoImage from '../assets/images/industry-graphics/auto.png';
import ServicesImage from '../assets/images/industry-graphics/pets.png';
import EducationImage from '../assets/images/industry-graphics/arts.png';
import HealthImage from '../assets/images/industry-graphics/health.png';
import OtherImage from '../assets/images/industry-graphics/other.jpg';

import OptionsContainer from './OptionsContainer';
import OptionContainer from './OptionContainer';
import HintText from './styled/HintText';

const INDUSTRIES = {
  Sports: {
    image: SportImage,
    refId: 3,
  },
  Beauty: {
    refId: 1,
    image: BeautyImage,
  },
  'Spa & Therapy': {
    refId: 12,
    image: SpaImage,
  },
  'Health Care': {
    refId: 4,
    image: HealthImage,
  },
  Auto: {
    refId: 8,
    image: AutoImage,
  },
  Services: {
    refId: 13,
    image: ServicesImage,
  },
  'Education & Arts': {
    refId: 14,
    image: EducationImage,
  },
  // Legal: {
  //   image: EducationImage,
  // },
  Other: {
    refId: 16,
    image: OtherImage,
  },
};

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

const Name = styled.div`
  font-size: 1.6rem;
  margin-top: 20px;
  text-align: center;
  overflow-wrap: break-word;
`;

const Image = styled.img`
  height: 200px;
  width: 160px;

  @media (max-width: 805px) {
    height: 100px;
    width: 80px;
  }
`;

function PickIndustry({onClick}) {
  return (
    <Container>
      <PromptText>
        <Trans>First, describe your industry:</Trans>
      </PromptText>
      <HintText>
        (<Trans>pick one to continue</Trans>)
      </HintText>
      <OptionsContainer>
        {Object.keys(INDUSTRIES).map((key) => (
          <OptionContainer
            key={key}
            onClick={() => onClick({...INDUSTRIES[key], name: key})}
            className="pick-industry-button"
            id={kebabCase(key + ' industry-button')}
          >
            <Image src={INDUSTRIES[key].image} />
            <Name>
              <Trans>{key}</Trans>
            </Name>
          </OptionContainer>
        ))}
      </OptionsContainer>
    </Container>
  );
}

export default PickIndustry;
