// eslint-disable-next-line no-unused-vars
import React from 'react';
import styled from 'styled-components';

const Header = styled.h4`
  font-weight: bold;
  /* text-decoration: underline; */
`;

const Description = styled.p`
  font-size: 1.4rem;
  margin-bottom: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* background-color: rgba(127, 17, 224, 0.2); */
  background-color: #332c65;
  /* color: #332c65; */
  color: #fff;
  padding: 10px 18px;
`;

function FactFooter({heading, text, style, className}) {
  return (
    <div className={className} style={{backgroundColor: 'white', ...style}}>
      <Container>
        <Header>{heading}</Header>
        <Description>{text}</Description>
      </Container>
    </div>
  );
}

export default FactFooter;
