import styled from 'styled-components';

const PromptText = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 1em;
  background-color: #8070fd;
  width: 100vw;
  padding: 18px;
  color: #fff;
  font-size: 1.8rem;
`;

export default PromptText;
