const places = {
  Sports: 'sports clubs, fitness centers, and athletic training facilities',
  Beauty: 'beauty salons, cosmetic studios, and nail salons',
  'Spa & Therapy': 'spa resorts, massage therapy centers, and wellness retreats',
  'Health Care': 'dental clinics, medical practices, and physical therapy offices',
  Auto: 'car dealerships, auto repair shops, and vehicle service centers',
  Services: 'cleaning services, law firms, and consulting agencies',
  'Education & Arts': 'art galleries, music schools, and educational institutions',
  Other: 'pet grooming businesses, event planning companies, and photography studios',
};

function getIndustryPlaces(industry) {
  return places[industry];
}

export default getIndustryPlaces;
