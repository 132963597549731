// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Spinner} from 'react-bootstrap';
import styled from 'styled-components';

const DimmedView = styled.div`
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 100;
  background-color: white;
  opacity: 0.5;
  align-items: center;
  justify-content: center;
`;

function Loader() {
  return (
    <DimmedView data-testid="loader-dimmedview">
      <Spinner data-testid="loader-spinner" animation="border" />
    </DimmedView>
  );
}

export default Loader;
