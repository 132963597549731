import styled from 'styled-components';

const OptionContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #d4d4d4;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
  padding: 30px;
  max-width: 300px;

  box-shadow: 0px 8px 24px 0 rgb(29 140 242 / 16%);

  :hover {
    border: 2px solid #44b8fe;
  }

  @media (max-width: 805px) {
    padding: 20px 8px;
    min-width: 120px;
  }
`;

export default OptionContainer;
