// eslint-disable-next-line no-unused-vars
import React, {useCallback, useState} from 'react';
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import {navigate} from 'gatsby';
import styled from 'styled-components';
import {Form as _Form} from 'react-bootstrap';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import PromptText from './PromptText';
import {Button, Column} from './styled';
import CTAButton from './styled/CTAButton';
import FactFooter from './styled/FactFooter';
import {BREAKPOINT} from '../helpers';
import getIndustryPlaces from '../helpers/getIndustryPlaces';

const Container = styled.div`
  display: flex;
  flex: 0;
  flex-direction: column;
  align-items: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  width: calc(100% - 28px);
  position: fixed;
  left: 14px;
  bottom: 12px;
`;

const InputField = styled(_Form.Control)`
  min-width: 250px;
`;

const PhoneInputField = styled.input`
  height: 46px;
  background-color: #fff;
  border-radius: 16px;

  padding: 10px 5px 10px 14px;
  font-size: 0.9em;
  border: 1px solid lightgray;
`;

const Form = styled(_Form)`
  margin-top: 18px;
  padding: 0px 24px;
  font-size: 1.6rem;
  padding-bottom: 144px;

  label {
    margin-bottom: 0.2rem;
    margin-left: 4px;
  }

  @media (min-width: ${BREAKPOINT}px) {
    margin-top: 48px;
  }
`;

function SignUpForm({onSubmit, onChange, onPhoneNumberChange, data, buttonTitle}) {
  const {t} = useTranslation();
  const [validated, setValidated] = useState(false);

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();

      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.stopPropagation();

        setValidated(true);
      } else {
        onSubmit(event);
      }
    },
    [onSubmit],
  );

  const handleChange = useCallback(
    (event) => {
      if (validated === true) {
        setValidated(false);
      }

      onChange(event);
    },
    [onChange, validated],
  );

  const handlePhoneNumberChange = useCallback(
    (value) => {
      if (validated === true) {
        setValidated(false);
      }

      onPhoneNumberChange(value);
    },
    [onPhoneNumberChange, validated],
  );

  const handleBackButtonClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(-1);
  };

  let industry, industryPlaces;
  try {
    industry = JSON.parse(localStorage.getItem('industry'));
    industryPlaces = t(getIndustryPlaces(industry.name));
  } catch (e) {
    console.error(e);
  }

  return (
    <Container>
      <PromptText>
        <Trans>Enter your information below</Trans>
      </PromptText>
      <Form id="sign-up" noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label className="required">
            <Trans>Company name</Trans>
          </Form.Label>
          <Form.Control
            required
            id="organizationName"
            className="cindy-input"
            type="text"
            // placeholder={t('Enter your business name')}
            onChange={handleChange}
            value={data.organizationName}
          />
          <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
            <Trans>Please provide a company name.</Trans>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label className="required">
            <Trans>Your name</Trans>
          </Form.Label>
          <Form.Control
            required
            id="name"
            className="cindy-input"
            type="text"
            value={data.name}
            // placeholder={t('Enter your business name')}
            onChange={handleChange}
          />
          <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
            <Trans>Please provide a name.</Trans>
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="sign-up__form-group">
          <Column>
            <Form.Label className="required">
              <Trans>Email</Trans>
            </Form.Label>
            <InputField
              className="cindy-input"
              required
              id="email"
              type="email"
              // placeholder={`${t('Enter your email')}*`}
              onChange={handleChange}
              value={data.email}
            />
            <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
              <Trans>Please provide a valid email.</Trans>
            </Form.Control.Feedback>
          </Column>
          <Column>
            <Form.Label className="required">
              <Trans>Phone number</Trans>
            </Form.Label>
            <PhoneInput
              style={{height: 60, paddingTop: 0}}
              required
              className="cindy-input"
              id="phoneNumber"
              type="tel"
              defaultCountry={localStorage.getItem('country')?.toUpperCase()}
              rules={{required: true}}
              value={data.phoneNumber}
              international
              withCountryCallingCode
              inputComponent={PhoneInputField}
              onChange={handlePhoneNumberChange}
            />
            <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
              <Trans>Please provide a phone number.</Trans>
            </Form.Control.Feedback>
          </Column>
        </Form.Group>
        {/* <Form.Group>
          <Form.Label>
            <Trans>Country</Trans>
          </Form.Label>
          <Form.Control
            required
            style={{height: 46, borderRadius: 12}}
            id="country"
            as="select"
            onChange={onChange}
            value={selectedCountry}
            disabled={isLoading}
          >
            {Object.keys(countries).map((key) => (
              <option key={key}>{countries[key]}</option>
            ))}
            <Form.Control.Feedback style={{marginTop: -10, marginBottom: 5}} type="invalid">
              <Trans>Please provide a country.</Trans>
            </Form.Control.Feedback>
          </Form.Control>
        </Form.Group> */}
        <div className="hide-on-mobile">
          <div style={{display: 'flex', gap: 8}}>
            <Button onClick={handleBackButtonClick} $border>
              <Trans>Back</Trans>
            </Button>
            <CTAButton
              style={{
                flex: 1,
                width: '100%',
                backgroundColor: '#02c569',
                borderColor: '#02c569',
              }}
              variant="primary"
              type="submit"
              className="submit-signup-button"
            >
              <Trans>{buttonTitle || 'Sign up'}</Trans>
            </CTAButton>
          </div>
          <FactFooter
            style={{marginTop: 32}}
            heading={<Trans>Actual Fact</Trans>}
            text={
              // eslint-disable-next-line max-len
              <Trans i18nKey="Cindy has helped tens of <1>{{industryPlaces}}<1/> to improve their return visits by 34% on average.">
                Cindy has helped tens of {{industryPlaces}} to improve their return visits by 34% on average.
              </Trans>
            }
          />
        </div>

        <div className="hide-on-desktop">
          <FactFooter
            heading={<Trans>Actual Fact</Trans>}
            text={
              // eslint-disable-next-line max-len
              <Trans i18nKey="Cindy has helped tens of <1>{{industryPlaces}}<1/> to improve their return visits by 34% on average.">
                Cindy has helped tens of {{industryPlaces}} to improve their return visits by 34% on average.
              </Trans>
            }
            style={{position: 'fixed', bottom: 72, left: 0, right: 0}}
          />
          <ButtonGroup>
            <Button onClick={handleBackButtonClick} $border>
              <Trans>Back</Trans>
            </Button>
            <CTAButton
              style={{
                flex: 1,
                backgroundColor: '#02c569',
                borderColor: '#02c569',
              }}
              variant="primary"
              type="submit"
              className="submit-signup-button"
            >
              <Trans>{buttonTitle || 'Sign up'}</Trans>
            </CTAButton>
          </ButtonGroup>
        </div>
      </Form>
    </Container>
  );
}

export default SignUpForm;
