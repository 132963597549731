// eslint-disable-next-line no-unused-vars
import React from 'react';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import {Trans} from 'gatsby-plugin-react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {green} from '@mui/material/colors';
import SectionHeader from './SectionHeader';
import AppStoreButton from '../assets/images/app-store-badge-alt.svg';
import GooglePlayButton from '../assets/images/google-play-badge.svg';
import {Row} from './styled';
import FactFooter from './styled/FactFooter';
import Rating from '@mui/material/Rating';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  max-width: 500px;
  min-height: calc(100vh - 180px);
  margin-top: 48px;

  @media (max-width: 1092px) {
    max-width: 100vw;
    margin-top: 32px;
  }
`;

const InstructionsContainer = styled.div`
  border: 2px solid #332c65;
  border-radius: 8px;
  padding: 8px 16px;
  margin-top: 32px;
`;

const StepText = styled.div`
  font-size: 1.8rem;
  text-align: center;
  color: #332c65;
`;

const DownloadButton = styled.img`
  height: 48px;
`;

function SuccessSignUp({phoneNumber}) {
  const registerDownloadClick = (event) => {
    if (typeof window !== 'undefined')
      if (window.fbq) {
        window.fbq('track', 'ClickDownloadButton', {
          platform: event.target.id === 'app-store-button' ? 'iOS' : 'Android',
        });
      }

    if (window.klaviyo) {
      window.klaviyo.push([
        'track',
        'Clicked download button',
        {
          platform: event.target.id === 'app-store-button' ? 'iOS' : 'Android',
        },
      ]);
    }
  };

  return (
    <Container>
      <CheckCircleIcon sx={{fontSize: 120, color: green['A400']}} />
      <SectionHeader style={{color: '#332c65', marginTop: 16, fontSize: '1.8rem'}}>
        <Trans>You have successfully signed up!</Trans>
      </SectionHeader>
      <InstructionsContainer className="hide-on-desktop">
        <StepText>
          <Trans>To continue, click to download our mobile app:</Trans>
        </StepText>
        <Row style={{marginTop: 8, gap: 8, marginBottom: 8, justifyContent: 'center'}}>
          <a href="https://apps.apple.com/bg/app/cindy-partner/id1480978237" onClick={registerDownloadClick}>
            <DownloadButton id="app-store-button" src={AppStoreButton} />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.cindyapp.provider" onClick={registerDownloadClick}>
            <DownloadButton id="google-play-button" src={GooglePlayButton} />
          </a>
        </Row>
        <Row style={{justifyContent: 'center', alignItems: 'center'}}>
          <Rating name="read-only" value={4.9} readOnly size="large" />
          <div style={{color: 'black', fontSize: '1.3rem', fontStyle: 'italic', marginLeft: 8}}>
            4.9 <Trans>Rating on App Store/Google Play</Trans>
          </div>
        </Row>
        <p style={{marginTop: 12}}>
          <Trans i18nKey="Tip: Use the number you provided <1>({phoneNumber})<1/> to sign in.">
            Tip: Use the number you provided ({phoneNumber}) to sign in.
          </Trans>
        </p>
      </InstructionsContainer>
      <div className="hide-on-mobile">
        <Alert sx={{fontSize: 16, marginTop: 4}} severity="success">
          {/* eslint-disable-next-line max-len */}
          <Trans i18nKey="We have sent you a text message to <1>{{phoneNumber}}<1/> with a download link.">
            We have sent you a text message to <strong style={{color: '#8070fd'}}>{{phoneNumber}}</strong> with a
            download link.
          </Trans>{' '}
          <Trans>
            Download our mobile app to get started. Use the same number to sign up and finish setting up your account.
          </Trans>
        </Alert>
        <Row style={{justifyContent: 'center', alignItems: 'center', marginTop: 16}}>
          <Rating name="read-only" value={4.9} readOnly size="large" />
          <div style={{color: 'black', fontSize: '1.6rem', fontStyle: 'italic', marginLeft: 4}}>
            4.9 <Trans>Rating on App Store/Google Play</Trans>
          </div>
        </Row>
        <FactFooter
          style={{marginTop: 36}}
          heading={<Trans>FREE WEBSITE</Trans>}
          text={
            <Trans>Pss... By signing up you also receive a FREE website! Check your email inbox to learn more.</Trans>
          }
        />
      </div>
      <FactFooter
        className="hide-on-desktop"
        style={{marginTop: 24, width: '100vw', position: 'fixed', bottom: 0}}
        heading={<Trans>FREE WEBSITE</Trans>}
        text={
          <Trans>Pss... By signing up you also receive a FREE website! Check your email inbox to learn more.</Trans>
        }
      />
    </Container>
  );
}

export default SuccessSignUp;
