// eslint-disable-next-line no-unused-vars
import React from 'react';
import {Link} from 'gatsby-plugin-react-i18next';
// import {StaticImage} from 'gatsby-plugin-image'; // TODO: Decide whether to use this
import styled from 'styled-components';
import PropTypes from 'prop-types';

import DefaultLogoUrl from '../assets/images/logo.png';

const LARGE_SIZE = 60;
const SMALL_SIZE = 36;

const LogoDiv = styled.div`
  margin: auto 0;
  flex: 0 1 ${(props) => (props.small ? `${SMALL_SIZE}px` : `${LARGE_SIZE}px`)};
  height: ${(props) => (props.small ? `${SMALL_SIZE}px` : `${LARGE_SIZE}px`)};
  width: ${(props) => (props.small ? `${SMALL_SIZE}px` : `${LARGE_SIZE}px`)};
  background-image: url(${(props) => props.imageUrl});
  background-repeat: no-repeat;
  background-size: cover;
`;

const Logo = ({small, imageUrl, navigateTo}) => {
  return <LogoDiv imageUrl={imageUrl || DefaultLogoUrl} small={small} as={Link} to={navigateTo || '/'} />;
};

Logo.propTypes = {
  small: PropTypes.bool,
  imageUrl: PropTypes.string,
};

export default Logo;
